import { CurrencyService } from './../../services/currency.service';
import { Router } from '@angular/router';
import { Order, OrderService } from 'src/app/services/checkout.service';
import { Component, OnInit, OnDestroy, ElementRef, QueryList, ViewChildren, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { pageTitles } from 'src/app/PageTitles';
import { SwitchThemeService } from 'src/app/services/switch-theme.service';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-gaming',
  templateUrl: './gaming.component.html',
  styleUrls: ['./gaming.component.less'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ])
  ]
})
export class GamingComponent implements OnInit, OnDestroy, AfterViewInit {
  validateForm!: FormGroup;
  planSelection = 'initial';
  gutterSize = 16;
  colSize = 7;
  colSize2 = 12;
  selectedPlan = 2;
  orderObject!: Order;
  nestedColSize = 8;
  currency: any;
  currencyList: any;
  @ViewChildren('plan') plan?: QueryList<ElementRef>;

  cryptCoinToEurRatio = 0.17;
  payBtnSize: NzButtonSize = 'large';

  private breakPointSubscription: Subscription;
  private breakPointSubscription2: Subscription;
  private breakPointsObj = {
    lg: '(max-width: 1199px)',
    md: '(max-width: 768px)',
    sm: '(max-width: 599px)',
  };
  gamingBigImg = '../../../assets/sections/gaming/2.jpg';

  columns = [
    { amount: 450, plan: 1, imageSrc: '../../../assets/sections/gaming/1.jpg' },
    { amount: 750, plan: 2, imageSrc: '../../../assets/sections/gaming/2.jpg' },
    { amount: 1000, plan: 3, imageSrc: '../../../assets/sections/gaming/3.jpg' }
  ];
  buttonStyle: object;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private switchThemeService: SwitchThemeService,
    private titleService: Title,
    private fb: FormBuilder,
    private orderService: OrderService,
    private router: Router,
    private currencyService: CurrencyService,
  ) {
    const buttonColor = (window as any).whitelabelConfig?.buttonColor || '#43bd52';
    const buttonTextColor = (window as any).whitelabelConfig?.buttonTextColor || '#f2f8f9';
    this.buttonStyle = { backgroundColor: buttonColor, borderColor: buttonColor, color: buttonTextColor };

    this.breakPointSubscription = this.breakpointObserver.observe([this.breakPointsObj.lg, this.breakPointsObj.md, this.breakPointsObj.sm]).
      subscribe(result => {
        this.gutterSize = result.matches ? 16 : 48;
        this.colSize2 = result.matches ? 24 : 12;
        if (result.breakpoints[this.breakPointsObj.lg] && !result.breakpoints[this.breakPointsObj.md]) {
          this.colSize2 = 12;
        }
      });
    this.breakPointSubscription2 = this.breakpointObserver.observe([this.breakPointsObj.sm]).subscribe(result => {
      this.colSize = result.matches ? 24 : 7;
      this.nestedColSize = result.matches ? 12 : 8;
      this.payBtnSize = 'default';
    });
    this.currencyList = this.currencyService.currencyList;
  }

  ngOnInit(): void {
    this.switchThemeService.gamingTheme();
    this.titleService.setTitle(pageTitles.gaming);

    this.validateForm = this.fb.group({
      cryptoAmount: [750, [Validators.required, Validators.min(1), Validators.max(2000)]]
    });
    this.currency = this.currencyService.selectedCurrency;
  }

  ngAfterViewInit(): void {
    this.planSelection = 'initial';
    this.plan?.toArray()[1].nativeElement.classList.add('selected-plan');
  }

  unsubscribe(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  gotoCheckout(): void {
    this.orderObject = { name: 'Gaming Coins', price: parseFloat(this.getPayableAmount()), quantity: this.getCryptoAmount(), };
    this.orderService.isCheckboxVisible = false;
    this.orderService.orderObject = this.orderObject;
    this.router.navigate(['/gaming-checkout-2']);
  }

  formatter(value: number): string {
    return `${value} Coins`;
  }

  removePlan(): void {
    this.plan?.toArray().forEach(elementRef => {
      elementRef.nativeElement.classList.remove('selected-plan');
    });
  }

  updateImage(): void {
    const value = this.validateForm.get('cryptoAmount')?.value;
    if (value > 1 && value <= 450) {
      this.gamingBigImg = this.columns[0].imageSrc;
    }
    else if (value > 450 && value <= 750) {
      this.gamingBigImg = this.columns[1].imageSrc;
    }
    else if (value > 750 && value <= 1000) {
      this.gamingBigImg = this.columns[2].imageSrc;
    }
    else if (value > 1000 && value <= 2000) {
      this.gamingBigImg = '../../../assets/sections/gaming/4.jpg';
    }
    if (value === 450 || value === 750 || value === 1000) {
      const index = this.columns.findIndex(column => column.amount === value);
      this.plan?.toArray()[index].nativeElement.classList.add('selected-plan');
    }
    else {
      this.removePlan();
    }
  }

  getPayableAmount(): string {
    const cryptoAmount: number = this.getCryptoAmount();
    const payableAmount = cryptoAmount * this.cryptCoinToEurRatio;
    return payableAmount.toFixed(2);
  }

  getCryptoAmount(): number {
    let cryptoAmount: number = this.validateForm.get('cryptoAmount')?.value;
    if (cryptoAmount !== cryptoAmount || cryptoAmount < 0) {
      cryptoAmount = 0;
    }
    return cryptoAmount;
  }

  submitForm(): void {
    // tslint:disable-next-line: forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }

  changePlan(plan: any): void {
    // tslint:disable-next-line: radix
    const planValue = parseInt(plan.getAttribute('data-plan-value'));
    const index = this.columns.findIndex(column => column.plan === planValue);
    const planAmount = this.columns[index].amount;
    this.removePlan();
    this.plan?.toArray()[planValue - 1].nativeElement.classList.add('selected-plan');
    this.validateForm.patchValue({ cryptoAmount: planAmount });
    this.selectedPlan = planValue;
  }

  currencyChange() {
    console.log(this.currency)
    this.currencyService.changeCurrency(this.currency);
  }

  ngOnDestroy(): void {
    if (this.breakPointSubscription) {
      this.breakPointSubscription.unsubscribe();
    }
    if (this.breakPointSubscription2) {
      this.breakPointSubscription2.unsubscribe();
    }
  }

}
