<app-loader 
    [showBackdrop]="showBackdrop" 
    [showInnerLoader]="showInnerLoader" 
    [showSuccessLoader]="false"
    [showFailLoader]="false"
></app-loader>
<div [style]="{'padding': '0 ' + ( gutterSize / 2 ) + 'px'}" class="checkout-container">
    <div nz-row nzAlign="middle" [nzGutter]="gutterSize">
        <div nz-col class="gutter-row" [nzSpan]="24">
            <h1 class="h1-responsive">Checkout Page</h1>
            <nz-divider></nz-divider>
        </div>
    </div>
    <div nz-row nzAlign="top" [nzGutter]="16" >
        <div nz-col class="gutter-row p-20" [nzSpan]="colSize">
            <!-- <app-address-detail></app-address-detail> -->
            <app-order-details (gamingFinalPayment)="checkoutPayment($event)" [isIframe]="true"></app-order-details>
        </div>
        <div nz-col class="gutter-row p-20" [nzSpan]="colSize*2" style="align-self: stretch;">
            <div style="width: 100%; height: 100%; background: rgba(255, 255, 255, 0.8);">
                <iframe src="" name="gamingCheckoutIFrame" frameborder="0" width="100%" height="100%">
                </iframe>
            </div>
        </div>
        <!-- <app-payment-details [cardVisibility]="true"></app-payment-details> -->
        <!-- <div nz-col class="gutter-row p-20" [nzSpan]="colSize">
        </div> -->
    </div>
    <!-- <form method="POST" [action]="actionLink" #whppForm>
        <input type="hidden" name="data" [value]="responseValue">
    </form> -->
</div>