import { SwitchThemeService } from 'src/app/services/switch-theme.service';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FailGuardService implements CanActivate {

  constructor(
    private router: Router,
    private switchTheme: SwitchThemeService
  ) { }

  canActivate(): boolean {

    const urlParams = new URLSearchParams(window.location.search);
    const txnRef = urlParams.get('txnRef');
    console.log(txnRef);
    if (localStorage.getItem('failAllowed') === 'false') {
      this.router.navigate(['/']);
      return false;
    }

    this.switchTheme.clearClasses();
    this.switchTheme.addClass('fail');

    if (txnRef !== null && txnRef.trim().length > 0 && window.self !== window.top) {
      window.parent.postMessage('fail ' + txnRef, window.parent.location.href);
    }
    return true;
  }
}
