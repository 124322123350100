import { CurrencyService } from './../../services/currency.service';
import { Order } from '../../services/checkout.service';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/checkout.service';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { pageTitles } from 'src/app/PageTitles';
import { SwitchThemeService } from 'src/app/services/switch-theme.service';
import { AppGalleryImage } from 'src/app/gallery/gallery.component';
import { NzButtonComponent, NzButtonSize } from 'ng-zorro-antd/button';

export interface ProductSize {
  label: string;
  value: string;
}

@Component({
  selector: 'app-fashion',
  templateUrl: './fashion.component.html',
  styleUrls: ['./fashion.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class FashionComponent implements OnInit, OnDestroy, AfterViewInit {

  gutterSize = 16;
  colSize = 12;
  selectedSize = 'M';
  currency :any;
  currencyList: any;
  orderObject!: Order;
  @ViewChildren('productSizes') productSizes?: QueryList<NzButtonComponent>;

  private breakPointSubscription: Subscription;
  private breakPointsObj = {
    lg: '(max-width: 1199px)',
    sm: '(max-width: 599px)',
  };

  productImages: AppGalleryImage[] = [
    {
      src: '../../../assets/sections/fashion/fashion-1.jpg'
    },
    {
      src: '../../../assets/sections/fashion/fashion-2.jpg'
    },
    {
      src: '../../../assets/sections/fashion/fashion-3.jpg'
    }
  ];

  sizes: ProductSize[] = [
    {
      label: 'S',
      value: 'S'
    },
    {
      label: 'M',
      value: 'M'
    },
    {
      label: 'L',
      value: 'L'
    },
  ];
  payBtnSize: NzButtonSize = 'large';

  panels = [
    {
      header: 'Product Description',
      active: true,
    },
    {
      header: 'Product Details',
      active: false,
    },
  ];

  quantities = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
  ];
  quantity = 1;
  buttonStyle: object;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private switchThemeService: SwitchThemeService,
    private titleService: Title,
    private orderService: OrderService,
    private router: Router,
    private currencyService: CurrencyService
  ) {
    const buttonColor = (window as any).whitelabelConfig?.buttonColor || '#43bd52';
    const buttonTextColor = (window as any).whitelabelConfig?.buttonTextColor || '#f2f8f9';
    this.buttonStyle = { backgroundColor: buttonColor, borderColor: buttonColor, color: buttonTextColor };

    this.breakPointSubscription = this.breakpointObserver.observe([this.breakPointsObj.lg, this.breakPointsObj.sm]).subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches
        ? 24
        : 12;

      if (result.breakpoints[this.breakPointsObj.sm]) {
        this.payBtnSize = 'default';
      }
    });
    this.currencyList = this.currencyService.currencyList;
  }

  removeSizeSelection(): void {
    this.productSizes?.toArray().forEach(size => {
      size.nzType = 'default';
    });
  }

  currencyChange() {
    console.log(this.currency);
    this.currencyService.changeCurrency(this.currency);
  }

  changeSize(sizeValue: string): void {
    const index = this.sizes.findIndex(size => size.value === sizeValue);
    this.removeSizeSelection();
    if (this.productSizes?.toArray()[index] !== undefined) {
      this.productSizes.toArray()[index].nzType = 'primary';
    }
    this.selectedSize = sizeValue;
  }

  gotoCheckout(): void {
    this.orderObject = { name: 'Cool T-shirt ' + this.selectedSize, price: 75 * this.quantity, quantity: this.quantity };
    this.orderService.isCheckboxVisible = true;
    this.orderService.orderObject = this.orderObject;
    this.router.navigate(['/fashion-checkout']);
  }

  ngOnInit(): void {
    this.switchThemeService.fashionTheme();
    this.titleService.setTitle(pageTitles.fashion);
    this.currency = this.currencyService.selectedCurrency;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.changeSize('M'));
  }

  ngOnDestroy(): void {
    if (this.breakPointSubscription) {
      this.breakPointSubscription.unsubscribe();
    }
  }

}
