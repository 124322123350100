<div class="order-details-container">
    <div class="order-wrapper border p-20">
        <h3 class="h3-responsive"><i nz-icon nzType="shopping-cart" nzTheme="outline"></i> Order Details</h3>
        <div class="mt-30">
            <div class="details-wrapper">
                <p>Product name:</p> <p style="text-align: end;"><span>{{orderObject.name | titlecase}}</span></p>
                <p>Quantity:</p> <p style="text-align: end;"><span>{{orderObject.quantity}}</span></p>
                <p>Product price:</p> <p style="text-align: end;"><span>{{orderObject.price | currency:currency}}</span></p>
            </div>
            <nz-divider></nz-divider>
            <div>
                <p>Sub Total:</p> <p style="text-align: end;"><span>{{orderObject.price | currency:currency}}</span></p>
                <p>Tax:</p> <p style="text-align: end;"><span>0.00</span></p>
                <p>Shipping:</p> <p style="text-align: end;"><span>0.00</span></p>
                <p>Discount:</p> <p style="text-align: end;"><span>0.00</span></p>
            </div>
            <nz-divider></nz-divider>
            <div class="final-price-wrapper">
                <p>Total price:</p> <p style="text-align: end;"><span>{{orderObject.price | currency:currency}}</span></p>
            </div>
            <span class="mb-20 mt-30">By completing this payment you agree to our <a href=""><strong>Terms and Services</strong></a></span>
            <button *ngIf="!isIframe" (click)="finalPayment()" nz-button nzShape="round" nzSize="large" class="final-pay-btn" [ngStyle]="buttonStyle">Complete Payment</button>
        </div>
    </div>
</div>
