import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';
import { Subscription } from 'rxjs/internal/Subscription';

export interface AppGalleryImage {
  src: string;
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.less']
})
export class GalleryComponent implements OnInit, OnDestroy {

  @Input() images!: AppGalleryImage[];
  effect = 'scrollx';
  enableSwipe = true;
  isModalVisible = false;
  galleryIndex = 0;
  galleryLastIndex = 0;
  galleryPrevDisable = true;
  galleryNextDisable = false;
  isModalCarouselVisible = false;
  controlsSize: NzSizeLDSType = 'default';
  private breakPointSubscription: Subscription;

  @ViewChild(NzCarouselComponent) galleryCarousel?: NzCarouselComponent;
  @ViewChild('modalCarousel') imgViewerCarousel?: NzCarouselComponent;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe(result => {
      this.controlsSize = result.matches ? 'small' : 'default';
    });
  }

  ngOnInit(): void {
    this.galleryLastIndex = this.images.length;
  }

  prev(): void {
    if (this.galleryIndex > 0 && this.galleryIndex <= this.galleryLastIndex) {
      this.galleryIndex--;
    }
    if (this.galleryIndex === 0) {
      this.galleryPrevDisable = true;
    }
    if (this.galleryIndex < this.galleryLastIndex) {
      this.galleryNextDisable = false;
    }
    this.galleryCarousel?.goTo(this.galleryIndex);
    // tslint:disable-next-line: no-unused-expression
    this.isModalVisible && this.isModalCarouselVisible ? this.imgViewerCarousel?.goTo(this.galleryIndex) : null;
  }
  next(): void {
    if (this.galleryIndex >= 0 && this.galleryIndex < this.galleryLastIndex) {
      this.galleryIndex++;
    }
    if (this.galleryIndex === this.galleryLastIndex - 1) {
      this.galleryNextDisable = true;
    }
    if (this.galleryIndex > 0) {
      this.galleryPrevDisable = false;
    }
    this.galleryCarousel?.goTo(this.galleryIndex);
    // tslint:disable-next-line: no-unused-expression
    this.isModalVisible && this.isModalCarouselVisible ? this.imgViewerCarousel?.goTo(this.galleryIndex) : null;
  }
  showModal(): void {
    this.isModalVisible = true;
    if (!this.isModalCarouselVisible) {
      setTimeout(() => {
        this.isModalCarouselVisible = true;
        setTimeout(() => this.imgViewerCarousel?.goTo(this.galleryIndex));
      }, 180);
    }
    // tslint:disable-next-line: no-unused-expression
    this.isModalCarouselVisible ? this.imgViewerCarousel?.goTo(this.galleryIndex) : null;
  }
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isModalVisible = false;
  }

  ngOnDestroy(): void {
    if (this.breakPointSubscription) {
      this.breakPointSubscription.unsubscribe();
    }
  }
}
