import { CurrencyService } from './../../services/currency.service';
import { Order } from '../../services/checkout.service';
import { Component, OnDestroy, OnInit, ViewChildren, QueryList, AfterViewInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { BreakpointObserver } from '@angular/cdk/layout';
import { SwitchThemeService } from 'src/app/services/switch-theme.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AppGalleryImage } from 'src/app/gallery/gallery.component';
import { pageTitles } from 'src/app/PageTitles';
import { NzButtonComponent, NzButtonSize } from 'ng-zorro-antd/button';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/checkout.service';


export interface FlowerColor {
  label: string;
  value: string;
}

@Component({
  selector: 'app-flowers',
  templateUrl: './flowers.component.html',
  styleUrls: ['./flowers.component.less'],
  encapsulation: ViewEncapsulation.None,

})
export class FlowersComponent implements OnInit, OnDestroy, AfterViewInit {

  gutterSize = 16;
  colSize = 12;
  payBtnSize: NzButtonSize = 'large';
  selectedColor = 'red';
  orderObject!: Order;
  currency:any;
  currencyList: any;
  @ViewChildren('flowerColors') flowerSelection?: QueryList<NzButtonComponent>;

  private breakPointSubscription: Subscription;
  private breakPointsObj = {
    lg: '(max-width: 1199px)',
    sm: '(max-width: 599px)',
  };

  productImages: AppGalleryImage[] = [
    {
      src: '../../../assets/sections/flowers/flower-1.jpg'
    },
    {
      src: '../../../assets/sections/flowers/flower-2.jpg'
    }
  ];

  flowerColors: FlowerColor[] = [
    {
      label: 'violet',
      value: 'violet'
    },
    {
      label: 'red',
      value: 'red'
    },
    {
      label: 'orange',
      value: 'orange'
    }
  ];

  panels = [
    {
      header: 'Product Description',
      active: true,
    },
    {
      header: 'Product Details',
      active: false,
    },
  ];

  quantities = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 }
  ];
  quantity = 1;
  buttonStyle: object;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private switchThemeService: SwitchThemeService,
    private titleService: Title,
    private router: Router,
    private orderService: OrderService,
    private currencyService:CurrencyService,
  ) {
    const buttonColor = (window as any).whitelabelConfig?.buttonColor || '#43bd52';
    const buttonTextColor = (window as any).whitelabelConfig?.buttonTextColor || '#f2f8f9';
    this.buttonStyle = { backgroundColor: buttonColor, borderColor: buttonColor, color: buttonTextColor };

    this.breakPointSubscription = this.breakpointObserver.observe([this.breakPointsObj.lg, this.breakPointsObj.sm]).subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches ? 24 : 12;
      if (result.breakpoints[this.breakPointsObj.sm]) {
        this.payBtnSize = 'default';
      }
    });
    this.currencyList = this.currencyService.currencyList;
  }

  changeColor(colorName: string): void {
    const index = this.flowerColors.findIndex(color => color.value === colorName);
    this.removeFlowerColors();
    this.selectedColor = colorName;
    // @ts-ignore: Unreachable code error
    this.flowerSelection?.toArray()[index].elementRef.nativeElement.classList.add('selected-color');
  }

  removeFlowerColors(): void {
    this.flowerSelection?.toArray().forEach(button => {
      // @ts-ignore: Unreachable code error
      button.elementRef.nativeElement.classList.remove('selected-color');
    });
  }
  gotoCheckout(): void {
    this.orderObject = {
      name: this.selectedColor + ' Roses',
      price: 27.09 * this.quantity,
      quantity: this.quantity,
      color: this.selectedColor
    };
    this.orderService.isCheckboxVisible = true;
    this.orderService.orderObject = this.orderObject;
    this.router.navigate(['/flowers-checkout']);
  }

  ngOnInit(): void {
    this.switchThemeService.flowersTheme();
    this.titleService.setTitle(pageTitles.flowers);
    this.currency = this.currencyService.selectedCurrency;
  }

  ngAfterViewInit(): void {
    // @ts-ignore: Unreachable code error
    this.flowerSelection?.toArray()[1].elementRef.nativeElement.classList.add('selected-color');
  }

  currencyChange(){
    console.log(this.currency)
    this.currencyService.changeCurrency(this.currency);
  }

  ngOnDestroy(): void {
    if (this.breakPointSubscription) {
      this.breakPointSubscription.unsubscribe();
    }
  }

}
