import { CurrencyService } from './currency.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// tslint:disable-next-line: typedef
function generateUUID() { // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
  // tslint:disable: only-arrow-functions
  // tslint:disable-next-line: typedef
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {// Use timestamp until depleted
      // tslint:disable-next-line: no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {// Use microseconds since page-load if supported
      // tslint:disable-next-line: no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // tslint:disable-next-line: no-bitwise
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}


export interface PaymentObj {
  customer: any;
  transaction: any;
  url: any;
  paymentDetail?: any;
  '3DSecure'?: any;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  readonly IP = (window as any).whitelabelConfig.IP;
  readonly PAYMENT_URL = (window as any).whitelabelConfig.PAYMENT_URL;

  constructor(
    private http: HttpClient,
    private currencyService: CurrencyService,
  ) { }

  prepareRequestObject(paymentDetails: any, isIFrame = false): object {
    const addressObj = paymentDetails.address;
    const orderObj = paymentDetails.order;
    const cardObj = paymentDetails.card;
    const paymentObject: PaymentObj = {
      customer: { id: !isIFrame ? 'cust001' : generateUUID(), ...addressObj },
      transaction: {
        txnReference: '',
        currencyCode: this.currencyService.selectedCurrency,
        txnAmount: orderObj.price,
      },
      url: {
        successURL: this.IP + '/success',
        failURL: this.IP + '/fail',
        cancelURL: this.IP + '/fail',
        isIFrame: !!isIFrame
      }
    };



    if (paymentDetails.apiType !== 'HPP' && !isIFrame) {
      paymentObject.paymentDetail = {
        cvv: cardObj.cvv,
        cardNumber: cardObj.cardNumber,
        cardHolderName: 'John Doe',
        expiryMonth: cardObj.month,
        expiryYear: cardObj.year,
      };
      paymentObject['3DSecure'] = {
        timezone: 330,
        browserColorDepth: 24,
        browserLanguage: 'en-GB',
        browserScreenHeight: 1080,
        browserScreenWidth: 1920,
        os: 'windows',
        browserAcceptHeader: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*',
        userAgent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.138 Safari/537.36',
        browserJavascriptEnabled: false,
        browserJavaEnabled: true,
        acceptContent: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
        browserIP: '192.1.1.1',
        challengeIndicator: '03',
        challengeWindowSize: '02'
      };
    }
    console.log(paymentObject);

    const requestObject = new HttpParams()
      .set('payLoad', btoa(JSON.stringify(paymentObject)))
      .set('apiType', paymentDetails.apiType)
      .set('defaultIntegration', (window as any).whitelabelConfig.defaultIntegration)
      .set('isIframe', isIFrame ? 'true' : 'false');
    return requestObject;
  }

  doPayment(paymentDetails: any): Observable<any> {
    localStorage.setItem('failAllowed', 'true');
    localStorage.setItem('successAllowed', 'true');
    const requestObject = this.prepareRequestObject(paymentDetails);
    // console.log(requestObject);

    return this.http.post(this.PAYMENT_URL, requestObject, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  doIframe(paymentDetails: any): Observable<any> {
    localStorage.setItem('failAllowed', 'true');
    localStorage.setItem('successAllowed', 'true');
    const requestObject = this.prepareRequestObject(paymentDetails, true);
    // console.log(requestObject);

    return this.http.post(this.PAYMENT_URL, requestObject, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  getTransactionStatus(txnReference: string): Observable<any> {
    const requestObject = new HttpParams().set('txnReference', txnReference ? txnReference : 'E17C4952-5200-4D2C-A8CB-2D92460321B5').set('apiType', 'status');

    return this.http.post(this.PAYMENT_URL, requestObject, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }
}
