import { CurrencyService } from './../../services/currency.service';
import { Order } from '../../services/checkout.service';
import { OrderService } from 'src/app/services/checkout.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { pageTitles } from 'src/app/PageTitles';
import { SwitchThemeService } from 'src/app/services/switch-theme.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzButtonSize } from 'ng-zorro-antd/button';

@Component({
  selector: 'app-crypto',
  templateUrl: './crypto.component.html',
  styleUrls: ['./crypto.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class CryptoComponent implements OnInit, OnDestroy {

  validateForm!: FormGroup;
  gutterSize = 16;
  colSize = 12;
  isScreenSm = false;
  cryptCoinToEurRatio = 100.1;
  currency: any;
  currencyList: any;
  // 347.1
  payBtnSize: NzButtonSize = 'large';
  orderObject!: Order;
  buttonStyle: object;

  private breakPointSubscription: Subscription;
  private smBreakPointSubscription: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private switchThemeService: SwitchThemeService,
    private titleService: Title,
    private fb: FormBuilder,
    private router: Router,
    private orderService: OrderService,
    private currencyService: CurrencyService,
  ) {
    const buttonColor = (window as any).whitelabelConfig?.buttonColor || '#43bd52';
    const buttonTextColor = (window as any).whitelabelConfig?.buttonTextColor || '#f2f8f9';
    this.buttonStyle = { backgroundColor: buttonColor, borderColor: buttonColor, color: buttonTextColor };
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 768px)').subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches ? 24 : 12;
    });
    this.smBreakPointSubscription = this.breakpointObserver.observe('(max-width: 600px)').subscribe(result => {
      this.isScreenSm = result.matches ? true : false;
      this.payBtnSize = 'default';
    });
    this.currencyList = this.currencyService.currencyList;
  }

  ngOnInit(): void {
    this.switchThemeService.cryptoTheme();
    this.titleService.setTitle(pageTitles.crypto);
    this.validateForm = this.fb.group({
      cryptoAmount: [0.20, [Validators.required, Validators.min(0.01), Validators.max(2)]]
    });
    this.currency = this.currencyService.selectedCurrency;
  }

  unsubscribe(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  formatter(value: number): string {
    return `${value} Crypt-Coin`;
  }

  getCryptoAmount(): number {
    let cryptoAmount: number = this.validateForm.get('cryptoAmount')?.value;
    if (cryptoAmount !== cryptoAmount || cryptoAmount < 0) {
      cryptoAmount = 0;
    }
    return cryptoAmount;
  }

  gotoCheckout(): void {
    this.orderObject = { name: 'Crypt-Coin', price: parseFloat(this.getPayableAmount()), quantity: this.getCryptoAmount() };
    this.orderService.isCheckboxVisible = false;
    console.log(this.orderService.isCheckboxVisible);
    this.orderService.orderObject = this.orderObject;
    this.router.navigate(['/crypto-checkout']);
  }

  getPayableAmount(): string {
    const cryptoAmount: number = this.getCryptoAmount();
    const payableAmount = cryptoAmount * this.cryptCoinToEurRatio;
    return payableAmount.toFixed(2);
  }

  submitForm(): void {
    // tslint:disable-next-line: forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }
  currencyChange() {
    console.log(this.currency);
    this.currencyService.changeCurrency(this.currency);
  }

  ngOnDestroy(): void {
    this.unsubscribe(this.breakPointSubscription);
    this.unsubscribe(this.smBreakPointSubscription);
  }
}
