import { PaymentService } from 'src/app/services/payment.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { pageTitles } from 'src/app/PageTitles';

@Component({
  selector: 'app-gaming-checkout',
  templateUrl: './gaming-checkout.component.html',
  styleUrls: ['./gaming-checkout.component.less']
})
export class GamingCheckoutComponent implements OnInit, OnDestroy {

  gutterSize = 16;
  colSize = 8;
  actionLink = '';
  responseValue = '';
  showBackdrop = false;
  showInnerLoader = true;
  private breakPointSubscription: Subscription;
  @ViewChild('whppForm') whppForm!: ElementRef;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private paymentService: PaymentService
  ) {
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 1024px)').subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches ? 24 : 8;
    });
  }
  unsubscribe(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.titleService.setTitle(pageTitles.checkout);
  }

  ngOnDestroy(): void {
    this.unsubscribe(this.breakPointSubscription);
  }

  checkoutPayment(eventObject: any): void {
    console.log(eventObject);
    this.showBackdrop = true;
    this.paymentService.doPayment({ ...eventObject, apiType: 'WHPP' }).subscribe(response => {
      console.log(response);
      this.actionLink = response.action;
      this.responseValue = response.value;
      setTimeout(() => this.whppForm.nativeElement.submit(), 50);
    }, error => {
      console.log(error);
    });
  }
}
